'use client';
import { Flex, Loader } from '@mantine/core';

export default function Loading() {
  return (
    <Flex align={'middle'} justify={'center'} w={'100%'} h={'100%'}>
      <Loader color='indigo' size='xl' variant='dots' />
    </Flex>
  );
}
